<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
      <v-card class="v-card-bottom-30 hidden-sm-and-up">
        <v-card-title><v-btn v-if="menuCode310 != 'null'" color="primary" to="/add-order"><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30 hidden-xs-only">
        <v-card-title>
          <h4>{{use_language.order}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.my_order}}</span>
          <v-spacer/>
          <v-btn v-if="menuCode310 != 'null'" color="primary" to="/add-order"><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30 mx-0">
        <v-card-text>
          <v-row>
            <v-col class="pa-0" cols="12" md="6" lg="2">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-select
                    :value="SHselectStatus"
                    @change="value => SHselectStatus = value"
                    autocomplete="off"
                    :items="SHitemsStatus"
                    item-key="text"
                    item-value="value"
                    label="สถานะใบสั่งซื้อ"
                    hide-details
                    dense
                    outlined
                  >
                    <template v-slot:label> <span>{{use_language.order_status}}</span> </template> </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-select
                        :value="select_search_parcel_status_id"
                        @change="value => select_search_parcel_status_id = value"
                        autocomplete="off"
                        :items="search_parcel_status_list"
                        item-text="text"
                        item-value="value"
                        outlined
                        hide-details
                        dense
                        label="สถานะการส่งพัสดุ"
                      >
                      <template v-slot:label> <span>{{use_language.parcel_delivery_status}}</span> </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-select
                      :value="select_search_paid_status_id"
                      @change="value => select_search_paid_status_id = value"
                      autocomplete="off"
                      :items="search_paid_status_list"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      dense
                      label="สถานะจ่าย COD"
                    >
                    <template v-slot:label> <span>{{use_language.cod_pay_status}}</span> </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pa-0" cols="12" md="6" lg="3">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-menu
                    ref="search_menu_date_range"
                    v-model="search_menu_date_range"
                    :close-on-content-click="false"
                    :return-value.sync="search_date_range"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :value="computed_search_date"
                          @change="value => computed_search_date = value"
                          autocomplete="off"
                          label="ช่วงวันที่เปิดออเดอร์"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          ref="dateRangeText"
                          outlined readonly
                          hide-details
                          dense
                          v-on="on"
                      >
                        <template v-slot:label> <span>{{use_language.open_order_date}}</span> </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="search_date_range" range no-title scrollable :allowed-dates="allowedDates" class="my-0">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="search_date_range = []">{{use_language.reset}}</v-btn>
                      <v-btn text color="primary" @click="search_menu_date_range = false">{{use_language.cancel_1}}</v-btn>
                      <v-btn text color="primary" @click="$refs.search_menu_date_range.save(search_date_range)">{{use_language.ok}}</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-menu
                      ref="menu_search_sent_date"
                      v-model="menu_search_sent_date"
                      :close-on-content-click="false"
                      :return-value.sync="sent_date_range"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_search_sent_date"
                          @change="value => computed_search_sent_date = value"
                          autocomplete="off"
                          label="วันที่ส่งของ"
                          outlined
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          dense
                          v-on="on"
                        >
                          <template v-slot:label> <span>{{use_language.delivery_date}}</span> </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="sent_date_range" range no-title :allowed-dates="allowedDates" class="my-0">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="sent_date_range = []">{{use_language.reset}}</v-btn>
                        <v-btn text color="primary" @click="menu_search_sent_date = false">{{use_language.cancel_1}}</v-btn>
                        <v-btn text color="primary" @click="$refs.menu_search_sent_date.save(sent_date_range)">{{use_language.ok}}</v-btn>
                      </v-date-picker>
                    </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-menu
                    ref="menu_search_tranferred_date"
                    v-model="menu_search_tranferred_date"
                    :close-on-content-click="false"
                    :return-value.sync="transferred_date_range"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computed_search_tranferred_date"
                        @change="value => computed_search_tranferred_date = value"
                        autocomplete="off"
                        label="วันที่แพ็คสินค้า"
                        outlined
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        dense
                        v-on="on"
                      >
                  <template v-slot:label> <span>{{use_language.pack_date}}</span> </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="transferred_date_range" range no-title :allowed-dates="allowedDates" class="my-0">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="transferred_date_range = []">{{use_language.reset}}</v-btn>
                      <v-btn text color="primary" @click="menu_search_tranferred_date = false">{{use_language.cancel_1}}</v-btn>
                      <v-btn text color="primary" @click="$refs.menu_search_tranferred_date.save(transferred_date_range)">{{use_language.ok}}</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pa-0" cols="12" md="6" lg="2">
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-text-field
                    :value="SHorderID"
                    @change="value => SHorderID = value"
                    autocomplete="off"
                    label="เลขออเดอร์"
                    outlined
                    hide-details
                    dense
                  >
                    <template v-slot:label> <span>{{use_language.order_number}}</span> </template>
                  </v-text-field>
                </v-col> 
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-text-field
                      :value="SH_ReferenceNumber"
                      @change="value => SH_ReferenceNumber = value"
                      autocomplete="off"
                      label="เลขอ้างอิง"
                      outlined
                      hide-details
                      dense
                    >
                    <template v-slot:label> <span>{{use_language.reference_number}}</span> </template>
                  </v-text-field>
                </v-col> 
              </v-row>
               <v-row>
                <v-col cols="12"  class="pb-0">
                   <v-select
                      v-model="channelListSeleced"
                      :items="channelList" 
                      label="ช่องทาง"
                      multiple 
                      item-value="uniqueID"
                      item-text="name"
                      return-object
                      hide-details
                      dense
                      clearable
                      outlined
                    >
                      <template v-slot:item="{item}">
                        <v-icon 
                            v-if="channelListSeleced !== null && channelListSeleced.includes(item)" 
                            color="primary" 
                            class="mr-3">
                            mdi-checkbox-marked
                        </v-icon>
                        <v-icon v-else class="mr-3">
                            mdi-checkbox-blank-outline
                        </v-icon>
                        <img :src="item.logo" width="20px;"/> 
                        <div class="ml-2">{{item.name}} </div> 
                    </template>
                    <template v-slot:selection="{ item }">
                      {{item.name}}
                    </template>
                  </v-select>
                </v-col> 
              </v-row>
            </v-col>
            <v-col class="pa-0" cols="12" md="6" lg="2">
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-text-field
                    :value="SHshipName"
                    @change="value => SHshipName = value"
                    autocomplete="off"
                    label="ชื่อผู้รับ"
                    hide-details
                    dense
                    outlined
                  >
                  <template v-slot:label> <span>{{use_language.recipient_name}}</span> </template>
                  </v-text-field>
                </v-col> 
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-text-field
                    :value="SHtrackingNo"
                    @change="value => SHtrackingNo = value"
                    autocomplete="off"
                    label="TrackingNo"
                    hide-details
                    dense
                    outlined
                  >
                  <template v-slot:label> <span>{{use_language.tracking_no}}</span> </template>
                  </v-text-field>
                </v-col> 
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-combobox
                    v-model="SH_shop_staff"
                    :items="SH_shop_staff_items"
                    label="สร้างโดย"
                    item-key="text"
                    item-value="value"
                    outlined
                    hide-details
                    dense
                  >
                  <template v-slot:label> <span>{{use_language.create_by}}</span> </template>
                  </v-combobox>
                </v-col> 
              </v-row>
            </v-col>
             <v-col class="pa-0" cols="12" md="6" lg="3">
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-text-field
                    :value="SH_phone"
                    @change="value => SH_phone = value"
                    autocomplete="off"
                    label="เบอร์โทรศัพท์"
                    outlined
                    hide-details
                    dense
                  >
                  <template v-slot:label> <span>{{use_language.tel}}</span> </template>
                  </v-text-field>
                </v-col> 
              </v-row>
              <v-row>
                <v-col cols="12"  class="pb-0">
                  <v-btn @click="Searching()" color="primary" class="mr-3">{{use_language.search}}</v-btn>
                  <v-btn @click="SearchingClearData()" outlined color="primary">{{use_language.reset}}</v-btn>
                </v-col> 
              </v-row>
               
            </v-col>
          </v-row>
        
          
       
        </v-card-text>
      </v-card>

      <!-- <v-row v-if="(selected == 9999) && (summary_parcel_status != null)" class="mx-0 mb-7">
        <v-col cols="12" class="hidden-sm-and-down">
          <v-row justify="center">
            <v-col md="auto" lg="auto" class="pa-0" align="center">
              <v-card dark color="info" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i4.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.ordinary_parcel}}</div>
                <h3 v-if="summary_parcel_status.NotCOD != null">{{ summary_parcel_status.NotCOD }}</h3>
                <h3 v-else class="font-weight-bold">0</h3>
              </v-card>
            </v-col>
            <v-col md="auto" lg="auto" class="pa-0 pr-3" align="center">
              <v-card dark color="red" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i5.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.cod_parcel}}</div>
                <h3 v-if="summary_parcel_status.COD != null">{{ summary_parcel_status.COD }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col v-show="menuCode500 != 'null'" cols="12" md="auto" lg="auto" class="pa-0" align="center">
              <v-card dark color="blue-grey" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i6.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.total_cod}}</div>
                <h3 v-if="summary_parcel_status.codAmount != null">{{ summary_parcel_status.codAmount }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col v-show="menuCode500 != 'null'" cols="12" md="auto" lg="auto" class="pa-0" align="center">
              <v-card dark color="indigo" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i7.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.waiting_for_transfer}}</div>
                <h3 v-if="summary_parcel_status.WaitingToTransfer != null">{{ summary_parcel_status.WaitingToTransfer }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col v-show="menuCode500 != 'null'" cols="12" md="auto" lg="auto" class="pa-0 pr-3" align="center">
              <v-card dark color="success" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i8.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.transferred}}</div>
                <h3 v-if="summary_parcel_status.Transferred != null">{{ summary_parcel_status.Transferred }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" lg="auto" class="pa-0" align="center">
              <v-card dark color="purple" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i9.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.shipping}}</div>
                <h3 v-if="summary_parcel_status.ParcelStatusID1 != null">{{ summary_parcel_status.ParcelStatusID1 }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" lg="1" class="pa-0" align="center">
              <v-card dark color="error" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i10.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.bounce}}</div>
                <h3 v-if="summary_parcel_status.ParcelStatusID2 != null">{{ summary_parcel_status.ParcelStatusID2 }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" lg="auto" class="pa-0" align="center">
              <v-card dark color="success" elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i11.jpg"  style="width: 40px" />
                </div>
                <div>{{use_language.successful_delivery}}</div>
                <h3 v-if="summary_parcel_status.ParcelStatusID3 != null">{{ summary_parcel_status.ParcelStatusID3 }}</h3>
                <h3 v-else>0</h3>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" lg="1" class="pa-0" align="center">
              <v-card elevation="0" tile class="pa-3">
                <div>
                  <img src="@/assets/icon_jpg/i12.jpg"  style="width: 40px" />
                </div>
                <div><font color="green">{{use_language.percent_success}}</font></div>
                <h3 v-if="summary_parcel_status.Percent != null"><font color="green">{{ summary_parcel_status.Percent }} %</font></h3>
                <h3 v-else><font color="green">0 %</font></h3>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0 hidden-md-and-up">
          <v-col cols="12" class="pa-0" align="center">
            <v-card dark color="info" elevation="0" tile class="pa-3">
              <div>{{use_language.ordinary_parcel}}</div>
              <h3 v-if="summary_parcel_status.NotCOD != null">{{ summary_parcel_status.NotCOD }}</h3>
              <h3 v-else class="font-weight-bold">0</h3>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0 pb-3" align="center">
            <v-card dark color="red" elevation="0" tile class="pa-3">
              <div>{{use_language.cod_parcel}}</div>
              <h3 v-if="summary_parcel_status.COD != null">{{ summary_parcel_status.COD }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col v-show="menuCode500 != 'null'" cols="12" class="pa-0" align="center">
            <v-card dark color="blue-grey" elevation="0" tile class="pa-3">
              <div>{{use_language.total_cod}}</div>
              <h3 v-if="summary_parcel_status.codAmount != null">{{ summary_parcel_status.codAmount  }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col v-show="menuCode500 != 'null'" cols="12" class="pa-0" align="center">
            <v-card dark color="indigo" elevation="0" tile class="pa-3">
              <div>{{use_language.waiting_for_transfer}}</div>
              <h3 v-if="summary_parcel_status.WaitingToTransfer != null">{{ summary_parcel_status.WaitingToTransfer }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col v-show="menuCode500 != 'null'" cols="12" class="pa-0 pb-3" align="center">
            <v-card dark color="purple" elevation="0" tile class="pa-3">
              <div>{{use_language.transferred}}</div>
              <h3 v-if="summary_parcel_status.Transferred != null">{{ summary_parcel_status.Transferred }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0" align="center">
            <v-card dark color="error" elevation="0" tile class="pa-3">
              <div>{{use_language.shipping}}</div>
              <h3 v-if="summary_parcel_status.ParcelStatusID1 != null">{{ summary_parcel_status.ParcelStatusID1 }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0" align="center">
            <v-card dark color="warning" elevation="0" tile class="pa-3">
              <div>{{use_language.bounce}}</div>
              <h3 v-if="summary_parcel_status.ParcelStatusID2 != null">{{ summary_parcel_status.ParcelStatusID2 }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0" align="center">
            <v-card dark color="success" elevation="0" tile class="pa-3">
              <div>{{use_language.successful_delivery}}</div>
              <h3 v-if="summary_parcel_status.ParcelStatusID3 != null">{{ summary_parcel_status.ParcelStatusID3 }}</h3>
              <h3 v-else>0</h3>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0" align="center">
            <v-card elevation="0" tile class="pa-3">
              <div><font color="green">{{use_language.percent_success}}</font></div>
              <h3 v-if="summary_parcel_status.Percent != null"><font color="green">{{ summary_parcel_status.Percent }} %</font></h3>
              <h3 v-else><font color="green">0 %</font></h3>
            </v-card>
          </v-col>
        </v-col>
      </v-row> -->
      <!-- new table tab  start -->

      <v-row class="mb-1">
        <v-col cols="6" align="left" class="px-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!authorize"  outlined v-bind="attrs" v-on="on" class="mr-1 pa-2">
                <span class="pl-2">{{use_language.carry_out}}</span>
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="inv_pre_print()">
                <v-list-item-title>{{use_language.print_document}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cover_pages()">
                <v-list-item-title>{{use_language.print_cover_sheet}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="6" align="right" class="px-0">
          <vue-excel-xlsx
              id="GetExcel"
              v-show="false"
              :data="datasetExcel_new"
              :columns="excel_order_header"
              :file-name="ExcelFileName"
              sheet-name="shee1"
              >
          </vue-excel-xlsx>

          <vue-excel-xlsx
              id="GetExcelShot"
              v-show="false"
              :data="datasetExcelShotFormat_new"
              :columns="excel_order_header_shot"
              file-name="รายงานข้อมูลออเดอร์"
              sheet-name="shee1"
              >
          </vue-excel-xlsx>
          <v-btn icon v-if="loadingExcel == 0" :disabled="!authorize"  size="1" color="success" dark @click="dialog_export_excel()"><v-icon>mdi-file-excel</v-icon></v-btn>
          <v-btn icon v-else size="1" color="info" dark ><v-icon >mdi-file-excel</v-icon><span>({{loadingExcel}}%)
            <v-progress-circular indeterminate
              color="white"
              size="25"
            ></v-progress-circular> </span>
          </v-btn>
          <!--  -->
          <v-btn v-show="IsHeaderShot == false"  color="" class="pa-2  mt-1"  icon  @click="IsHeaderShot = true">
               <v-icon  size="16" class="pr-1">fa-list</v-icon>
          </v-btn>
          <v-btn  v-show="IsHeaderShot == true" color="" class="pa-2  mt-1"  icon @click="IsHeaderShot = false">
                <v-icon size="16" class="pr-1">fa-table</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-tabs v-model="tab_list" dark background-color="primary" show-arrows>
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab @click="tab_list = 0, IsSearch = false, offsets = 0, select_shipping_status_button(9999)">{{use_language.all_}} <span v-if="IsSearch == false">({{ this.summary_shipping_status.SumAllShippingStatus }})</span> <span v-else>({{this.total_search_data_order_master}})</span> </v-tab>
          <v-tab @click="tab_list = 1, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['OpenOrder'])">{{use_language.open_order}} ({{summary_shipping_status.OpenOrder}})</v-tab>
          <v-tab @click="tab_list = 2, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['WaitCheck'])">{{use_language.waiting_check_balance}} ({{summary_shipping_status.WaitCheck}})</v-tab>
          <v-tab @click="tab_list = 3, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['WaitPack'])">{{use_language.wati_pack}} ({{summary_shipping_status.WaitPack}})</v-tab>
          <v-tab @click="tab_list = 4, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['Packing'])">{{use_language.packing}} ({{summary_shipping_status.Packing}})</v-tab>
          <v-tab @click="tab_list = 5, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['Packed'])">{{use_language.neatly_packed}} ({{summary_shipping_status.Packed}})</v-tab>
          <v-tab @click="tab_list = 6, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['Ready'])">{{use_language.prepare_ship}} ({{summary_shipping_status.Ready}})</v-tab>
          <v-tab @click="tab_list = 7, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['Sent'])">{{use_language.shipped}} ({{summary_shipping_status.Sent}})</v-tab>
          <v-tab @click="tab_list = 8, IsSearch = false, offsets = 0, select_shipping_status_button(shippingStatusDict['Cancel'])">{{use_language.cancel}} ({{summary_shipping_status.Cancel}})</v-tab>
        </v-tabs>
      </v-card>

      <!-- แสดงจอใหญ่ -->

      <v-tabs-items v-model="tab_list" class="hidden-xs-only" touchless>
        <v-tab-item v-for="j in 9" :key="j" style=" background-color: #F2F4F8">
          <div flat >
            <v-data-table
              v-if="IsHeaderShot == false"
              :headers="headersShow"
              :items="showdataTable"
              class="elevation-0 packhai-border-table"
              v-model="selected_order"
              :single-select="singleSelect"
              item-key="id"
              show-select
              hide-default-footer
              :items-per-page="10000">
              <template v-slot:item.num="{ item }">
                {{ gen_num_data_table(showdataTable.indexOf(item)) }}
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn style="text-decoration: underline" color="primary" text @click="detail_page(item.id)">{{ format_order_id(item.id) }}</v-btn><br><span v-if="item.isPreOrder == 1" class="text-info"><b>{{use_language.pre_order}}</b></span>
              </template>
              <template v-slot:item.channelLogo="{ item }">
                  <v-avatar size="25px">
                    <img :src="item.channelLogo" @click="image_click(item.channelLogo)"/>
                  </v-avatar>
              </template>
              <template v-slot:item.expressCompanyLogo="{ item }">
                  <!-- <v-avatar size="25px"> -->
                    <img style="width: 60px" :src="item.expressCompanyLogo" @click="image_click(item.expressCompanyLogo)"/>
                  <!-- </v-avatar> -->
              </template>
              <template v-slot:item.createdDatetime="{ item }">
                {{ set_format_date_time(item.createdDatetime) }}
              </template>
              <template v-slot:item.receiverName="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div  v-bind="attrs"  v-on="on" style="width: 250px" class="text-truncate">
                      <span v-if=" item.receiverName != null">{{ item.receiverName }}</span>
                      <span v-else>{{ item.tempAddress }}</span>
                    </div>

                  </template>
                  <span>
                    <span v-if=" item.receiverName != null">{{ item.receiverName }}</span>
                    <span v-else>{{ item.tempAddress }}</span>
                  </span>
                </v-tooltip>


              </template>
              <template v-slot:item.orderTotalAmount="{ item }">
                {{ format_price( item.orderTotalAmount ) }}
              </template>
              <template v-slot:item.createdByStaffName="{ item }">
                {{ item.createdByStaffShopName }}
              </template>
              <template v-slot:item.salemanName="{ item }">
                {{ item.salemanName }}
              </template>
              <template v-slot:item.isCod="{ item }">
                <span v-if="item.isCOD == false"><v-chip color="#0951e5" outlined>{{use_language.ordinary}}</v-chip></span> <span v-else ><v-chip color="primary" style="width: 70px; padding-left: 20px" dark>{{use_language.cod}}</v-chip></span>
              </template>
              <template v-slot:item.cancleorder="{ item }">
                <span v-if="item.shippingStatusID == 10 || item.shippingStatusID == 20 ||item.shippingStatusID == 30 ">
                  <v-btn color="danger" @click="cancle_order(item)" dark outlined>{{use_language.cancel}}</v-btn>
                </span>
              </template>
              <template v-slot:item.referenceNumber="{ item }">
                <span v-if="item.referenceSourceID == 6">
                  {{item.realReferenceNumber}}
                </span>
                <span v-else>
                  {{ item.referenceNumber}}
                </span>
              </template>
              <template v-slot:item.shippingStatusName="{ item }">
                <span v-if="item.shippingStatusID == 10" style="color: black;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 40" style="color: orange;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 50" style="color: blue;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 70" style="color: green;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 90" style="color: red;">{{ item.shippingStatusName }}</span>
              </template>
              <template v-slot:item.parcelStatusName="{ item }">
                <span v-if="item.parcelStatusID == 0" style="color: purple;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 1" style="color: orange;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 2" style="color: red;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 3" style="color: #ff7ac0 ;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 4" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 5" style="color:green;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID ==6" style="color:red;">{{ item.parcelStatusName }}</span>
                <span v-else>{{ item.parcelStatusName }}</span>
              </template>
            </v-data-table>
            <div v-else class="my-2" v-for="item in showdataTable" :key="item.id">
              <v-card>
                <v-card-text class="py-2">
                  <v-row>
                    <v-col>
                      <table style="width:100%">
                        <td style="width:80px; vertical-align:middle" >
                          <a style="color: red" @click="detail_page(item.id)"><b><u>{{ format_order_id(item.id) }}</u></b> </a>
                        </td>
                        <td style="border-left: 2px solid #f0f0f0;" v-if="item.referenceNumber != null">
                          <span class="pl-5"  > เลขอ้างอิง :  <span style="color: black"> {{ item.referenceNumber }}</span></span>
                        </td>
                        <td class="text-right">
                          <span style="color: black" >{{ set_format_date_time( item.createdDatetime) }}</span>
                        </td>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col class="pt-1" cols="2" md="2" lg="2" xl="1" style="border-right: 1px solid #EEEEEE;">
                      <a style="color: red" @click="detail_page(item.token)"><b><u>{{ format_order_id(item.id) }}</u></b> </a>
                    </v-col>
                    <v-col class="pt-1" cols="5" md="5" lg="5" xl="5">
                      <span v-if="item.referenceNumber != null" > เลขอ้างอิง :  <span style="color: black"> {{ item.referenceNumber }}</span></span>
                    </v-col>
                    <v-col class="pt-1" cols="5" md="5" lg="5" xl="6" align="right">
                      <span style="color: black" >{{ set_format_date_time( item.createdDatetime) }}</span>
                    </v-col>
                  </v-row> -->
                  <v-divider/>
                    <v-row>
                      <v-col class="py-2" cols="3">
                        <span> ลูกค้า : </span><br>
                        <span v-if="item.receiverName != null" style="color: black"> {{ item.receiverName }} </span>
                        <span v-else style="color: black">  {{ item.tempAddress }}</span>
                      </v-col>
                      <v-col class="py-2" cols="3">
                        <span> ยอดขาย : </span><br>
                        <span style="color: black"> {{ format_price( item.orderTotalAmount) }} </span><br>
                        <span v-if="item.isCOD" ><v-icon style="font-size:6px" color="red">fa fa-circle</v-icon> <span style="color: black" ><b>COD</b></span>  </span>
                        <!-- <span v-else><v-icon style="font-size:6px" color="blue">fa fa-circle</v-icon> ธรรมดา</span> -->
                      </v-col>
                      <v-col class="py-2" cols="3">
                        <span> สถานะออเดอร์ : </span><br>
                          <span v-if="item.shippingStatusID == 10" style="color: black;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 40" style="color: orange;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 50" style="color: blue;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 70" style="color: green;">{{ item.shippingStatusName }}</span>
                          <span v-if="item.shippingStatusID == 90" style="color: red;">{{ item.shippingStatusName }}</span>
                          <span v-else>{{ item.shippingStatusName }}</span>
                      </v-col>
                      <v-col class="py-2" cols="3">
                        <span><img style="width: 50px" :src="item.expressCompanyLogo" @click="image_click(item.expressCompanyLogo)"/></span><br>
                        <span v-if="item.trackingNo != null" > TrackingNo : <span style="color: black"> {{ item.trackingNo }}</span>  </span><br>
                        <span v-if="item.parcelStatusID != null"> สถานะ :
                            <span v-if="item.parcelStatusID == 0" style="color: purple;">{{ item.parcelStatusName }}</span>
                            <span v-else-if="item.parcelStatusID == 1" style="color: orange;">{{ item.parcelStatusName }}</span>
                            <span v-else-if="item.parcelStatusID == 2" style="color: red;">{{ item.parcelStatusName }}</span>
                            <span v-else-if="item.parcelStatusID == 3" style="color: green;">{{ item.parcelStatusName }}</span>
                            <span v-else-if="item.parcelStatusID == 4" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                            <span v-else-if="item.parcelStatusID == 5" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                            <span v-else>{{ item.parcelStatusName }}</span>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-0" cols="12">
                        <table style="width:100%">
                          <td style="width:60px; vertical-align:middle" >
                            <span class="pb-5">ช่องทาง : </span>
                          </td>
                          <td>
                            <img style="width: 20px" :src="item.channelLogo" @click="image_click(item.channelLogo)"/>
                          </td>
                          <td class="text-right">
                              <span>สร้างโดย : <span style="color: black">{{ item.createdByStaffShopName }} </span>    , พนักงานขาย : <span style="color: black">  {{ item.salemanName }}</span></span>
                          </td>
                        </table>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <!-- new table tab end -->



      <!-- แสดงจอมือถือ -->
      <v-row class="hidden-sm-and-up mx-0" align="center">

        <v-col cols="12" class="pa-0">
          <v-card v-for="(item, index) in showdataTable" :key="index" style="border-radius :0px;" class="my-3" elevation="0" @click="detail_page(item.id)">
            <v-row class="px-3 fz-14 mx-0 pb-2">
              <v-col cols="6" align="left" class="pt-4"><span style="color: red;"><b> <u>{{ format_order_id(item.id) }}</u></b></span><span v-if="item.isPreOrder == 1" class="text-info"><b>{{use_language.pre_order}}</b></span> </v-col>
              <v-col cols="6" align="right">
                <!-- <span v-if="item.isCOD == false"><v-chip color="#0951e5" outlined>{{use_language.ordinary}}</v-chip></span> <span v-else ><v-chip color="primary" style="width: 70px; padding-left: 20px" dark>{{use_language.cod}}</v-chip></span> -->
                <span v-if="item.shippingStatusID == 10" style="color: black;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 40" style="color: orange;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 50" style="color: blue;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 70" style="color: green;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 90" style="color: red;">{{ item.shippingStatusName }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mx-0 py-3">
              <v-col cols="6" align="left"><span>{{use_language.create_date}}</span><br>
                <span class="fz-12"> <b> {{ set_format_date_time(item.createdDatetime) }} </b> </span>
              </v-col>
              <v-col cols="6" align="right">
                <img style="width: 30px" :src="item.channelLogo" @click="image_click(item.channelLogo)"/>
              </v-col>
            </v-row>
            <v-row class="px-3 mx-0">
              <v-col cols="12" align="center" class="py-3">
                <span>ยอดขาย</span><br>
                <span style="font-size: 24px; font-weight: bold; color: brack;" >{{ format_price(item.orderTotalAmount) }} ฿</span> <br>
                <span v-if="item.isCOD"><v-icon style="font-size:6px" color="red">fa fa-circle</v-icon> COD</span>
                <!-- <span v-else><v-icon style="font-size:6px" color="blue">fa fa-circle</v-icon> ธรรมดา</span> -->
              </v-col>
            </v-row>
            <v-row class="px-3 mx-0 fz-14">
              <v-col cols="12" align="left" class="py-0 pt-2">
                <span>{{use_language.recipient_name}} : </span> <span v-if="item.referenceNumber == null"><b> {{ item.receiverName }}</b></span>
                <span v-else><b> {{ item.tempAddress }}</b></span>
              </v-col>
              <v-col cols="12" align="left" class="pt-0 pb-2">
                <span>{{use_language.create_by}} : </span><span><b>{{ item.createdByStaffShopName }}</b></span>
              </v-col>
              <v-col cols="12" align="left" class="pt-0 pb-2">
                <span>{{use_language.salesperson}} : </span><span><b>{{ item.salemanName }}</b></span>
              </v-col>
              <v-col cols="12" align="left" class="pt-0 pb-2">
                <span>{{use_language.transport}} : </span>
                <img style="width: 70px" :src="item.expressCompanyLogo" @click="image_click(item.expressCompanyLogo)"/>
              </v-col>
            </v-row>
            <v-divider v-if="item.trackingNo != null"></v-divider>
            <v-row v-if="item.trackingNo != null" class="px-3 mx-0 fz-14">
              <v-col cols="8" align="left" class="pt-4">
                <span>{{use_language.tracking_no}} : </span> <span><b> {{ item.trackingNo }}</b></span>

              </v-col>
              <v-col cols="4" align="right" >
                <span v-if="item.parcelStatusID == 0" style="color: purple;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 1" style="color: orange;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 2" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 3" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 4" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 5" style="color: green;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 6" style="color: red;">{{ item.parcelStatusName }}</span>
                <span v-else>{{ item.parcelStatusName }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row  class="mx-0">
        <v-col cols="12" align="center">
          <div class="text-center" @click="changePage()">
            <v-pagination
              v-model="page"
              :length="pageLength"
              :total-visible="7"
              color="primary"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog_excel" scrollable max-width="600px" persistent >
        <v-card elevation="1" class="ma-0">
          <v-card-title class="primary">
            <span style="color:white;">{{use_language.export_excel}}</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog_excel = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text style="height: 325px;">
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"><span> <b> {{use_language.status}} : </b></span></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <v-select
                  :value="dialog_excel_select"
                  @change="value => dialog_excel_select = value"
                  autocomplete="off"
                  ref="dialog_excel_select"
                  :items="dialog_excel_items"
                  item-key="text"
                  item-value="value"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"> <span><b> {{use_language.start_date}} : </b></span></v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="startDate"
                      @change="value => startDate = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details
                      style="width: 290px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menutime1"
                  v-model="menutime1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time1"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time1"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menutime1"
                    v-model="time1"
                    full-width
                    @click:minute="$refs.menutime1.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"> <span><b> {{use_language.end_date}} : </b></span></v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="endDate"
                      @change="value => endDate = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details
                      style="width: 290px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menutime2"
                  v-model="menutime2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time2"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menutime2"
                    v-model="time2"
                    full-width
                    @click:minute="$refs.menutime2.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"  class="pb-0"></v-col>
              <v-col cols="12" md="9" xl="9" lg="9"  class="pb-0">
                <v-checkbox v-model="IsSkipSecondRow" label="ใส่ข้อมูลทุกแถวหากในออเดอร์นั้นมีสินค้ามากกว่า 1 รายการ">  </v-checkbox>
                
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <v-btn v-if="ExcelType == false" color="primary" @click="get_count_order_excel_new(), dialog_excel= false">{{use_language.excel}} </v-btn>
                <v-btn v-if="ExcelType == true" color="primary" @click="get_count_order_excel_new_shot_format(), dialog_excel= false">{{use_language.excel}} </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog  v-model="inv_dialog_printinvoice" scrollable persistent max-width="45%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-card-title>
            <span > {{use_language.print_document}} </span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="inv_dialog_printinvoice=false" > <v-icon>mdi-close</v-icon> </v-btn></v-card-title>
          <v-card-text>
            <v-row row align="center">
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.section}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="4">
                <v-select
                  ref="refinvtitleprintmain"
                  v-model="invoice_print.titleprint_main"
                  autocomplete="off"
                  :items="inv_titleprintselect"
                  item-text="text"
                  item-value="value"
                  label="เลือกหัวข้อ"
                  return-object
                  dense
                  hide-details
                  outlined
                  />
                     <template v-slot:label> <span>{{use_language.choose_topic}}</span> </template>

              </v-col>
              <v-col class="pb-0" cols="12" md="1">
                <h1 class="text-center">/</h1>
              </v-col>
              <v-col class="pb-0" cols="12" md="4">
                <v-select
                  ref="refinvtitleprintsecondary"
                  v-model="invoice_print.titleprint_secondary"
                  autocomplete="off"
                  :items="inv_titleprintselect"
                  item-text="text"
                  item-value="value"
                  label="เลือกหัวข้อ"
                  return-object
                  dense
                  hide-details
                  outlined
                  />
                  <template v-slot:label> <span>{{use_language.choose_topic}}</span> </template>

              </v-col>
            </v-row>
            <!-- <v-row row >
              <v-col class="pb-0" cols="12" md="3">
                <h4>วันที่ : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-menu
                  ref="inv_menu_date_print"
                  v-model="inv_menu_date_print"
                  :close-on-content-click="false"
                  :return-value.sync="invoice_print.printorder[0].invorder.date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        ref="refdate"
                        :value="invoice_print.printorder[0].date"
                        @change="value => computed_date_print = value"
                        autocomplete="off"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice_print.printorder[0].date"
                    @input="inv_menu_date_print = false,
                    $refs.inv_menu_date_print.save(invoice_print.printorder[0].date)"
                    no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <v-row row>
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.choose_document_size}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-radio-group v-model="sizepage" row>
                  <v-radio label="ขนาด a4" value= "a4" ></v-radio>
                  <v-radio label="ขนาด 80 mm" value= "small"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row row >
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.document_number}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-data-table
                    :headers="invorderhead"
                    :items="invoice_print.invorder"
                    class="elevation-0 packhai-border-table"
                    hide-default-footer
                    :items-per-page="50">
                    <template v-slot:item.orderid="{ item }">{{format_order_id(item.orderId)}}</template>
                    <template v-slot:item.dateString="{ item }">
                      <v-menu
                          ref="startDateMenu"
                          v-model="item.startDateMenu"
                          :close-on-content-click="true" 
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.dateString"
                              @change="value => item.date = value"
                              autocomplete="off"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              dense
                              hide-details
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.date"
                            @input="item.startDateMenu = false;updateFormattedDate(item)"
                            no-title
                            class="my-0"
                          ></v-date-picker>
                        </v-menu>
                    </template>
                    <template v-slot:item.invoiceno="{ item }">
                        <v-text-field
                          ref="refinvorder"
                          :value="item.invoiceNo"
                          @change="value => item.InvoiceNo = value"
                          outlined
                          dense
                          hide-details
                          autocomplete="off"
                          />
                    </template>
                    <template v-slot:item.delete="{ item }">
                      <v-btn color="red" icon @click="remove_data_invoice(invoice_print.invorder.indexOf(item))">
                        <v-icon>
                          fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </template>


                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn color="green " dark @click="inv_printinvoice()" > {{use_language.print_document}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_printcoverpage" scrollable persistent max-width="45%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-card-title>
            <span > {{use_language.print_cover_sheet}} </span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_printcoverpage=false" > <v-icon>mdi-close</v-icon> </v-btn></v-card-title>
          <v-card-text>
            <v-row row align="center">
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.choose_document_size}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-radio-group v-model="coverpages_print.sizepage" row hide-details>
                  <v-radio label="ขนาด a4" value= "a4" ></v-radio>
                  <v-radio label="ขนาด 10x10 cm" value= "small"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn color="green " dark @click="printcoverpage()" > {{use_language.print_document}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_photo" max-width="1200">
        <v-card class="my-0">
          <v-card-text>
            <v-row >
              <v-col cols="12">
                <v-img v-if="dialog_photo_link != null" :src="dialog_photo_link" aspect-ratio="1.5" contain></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { orderService_dotnet, shopService_dotnet } from '@/website/global'
    import { format_number, format_order_id, format_price, FormatInvoice, SetToDateTime,get_languages } from '@/website/global_function'
    import { shippingStatusDict } from '@/website/statusDict'
    import { token_api    } from '@/website/backend/token'
import { reportService_dotnet } from '../../global'

    // import VueBarcode from 'vue-barcode'
    export default {
        components: {
            Loading,
            // 'barcode': VueBarcode
        },

        data: () => ({
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
            channelListSeleced:[],
            channelList:[],
            ShopID:localStorage.getItem("shop_id"),
            page_loading: true,
            loading: false,
            use_language: null,
            set_language: null,
            authorize:false,
            summary_shipping_status: null,
            total_search_data_order_master: 0,
            isFullscreen: false,
            dialog_photo: false,
            dialog_photo_link: null,

            tab_list: null,
            IsSearch: false,
            ExcelFileName:'',
            shippingStatusDict,
            // search
            searchData: null,
            SHitemsStatus: [
              {text: 'ทั้งหมด', value: 9999},
              {text: 'เปิดออร์เดอร์', value: 10},
              {text: 'รอตรวจยอด', value: 20},
              {text: 'รอแพ็ค', value: 30},
              {text: 'กำลังแพ็ค', value: 40},
              {text: 'แพ็คเรียบร้อย', value: 50},
              {text: 'เตรียมจัดส่ง', value: 60},
              {text: 'จัดส่งแล้ว', value: 70},
              {text: 'ยกเลิก', value: 90},
            ],

            SHitemsStatusDialog: [
              {text: 'เปิดออร์เดอร์', value: 10},
              {text: 'รอตรวจยอด', value: 20},
              {text: 'รอแพ็ค', value: 30},
              {text: 'กำลังแพ็ค', value: 40},
              {text: 'แพ็คเรียบร้อย', value: 50},
              {text: 'เตรียมจัดส่ง', value: 60},
              {text: 'จัดส่งแล้ว', value: 70},
              {text: 'ยกเลิก', value: 90},
            ],
            SHselectStatus: 9999,
            SHshipName: null,
            SHchanelFackbook: null,
            SHchanelLine: null,
            SHchanelEmail: null,
            SHtrackingNo: null,
            SHorderID: null,
            SH_shop_staff: null,
            SH_phone : null,
            SH_ReferenceNumber : null,
            // SH_shop_staff_items: null,
            SH_shop_staff_items: null,
            search_menu_date_range: false,
            search_date_range: [],
            // end search

            // printer
            PrintOrderID: null,
            PrintCODAmount: null,
            PrintShopName: null,
            PrintShopPhone: null,
            PrintShopAddress: null,
            PrintCusName: null,
            PrintCusPhone: null,
            PrintCusAddress: null,


            // search parcel
            summary_parcel_status: null,
            select_search_paid_status_id: null,
            search_paid_status_list: [{text: 'ทุกแบบ', value: 0}, {text: 'โอนแล้ว', value: 1}, {text: 'ยังไม่ได้โอน', value: 2}],
            select_search_parcel_status_id: null,
            search_parcel_status_list: [{text: 'ทุกแบบ', value:-1}, {text: 'ระหว่างจัดส่ง', value: 1},{text: 'กำลังตีกลับ', value: 2},{text: 'ตีกลับแล้ว รอคืนสต๊อก', value: 3}, {text: 'ตีกลับแล้ว คืนสต๊อกแล้ว', value: 4}, {text: 'จัดส่งสำเร็จ', value: 5}],
            menu_search_sent_date: false,
            sent_date_range: [],
            menu_search_tranferred_date: false,
            transferred_date_range: [],
            // end search parcel
            IsSkipSecondRow:false,
            // Approve
            CanApprove: null,
            dialogMunnyTransfer: false,
            ApproveOrderID: null,
            show_more_detail: false,
            menuCode500: 'null',
            SeeOtherPeoplesOrder: null,
            menuCode310: 'null',
            canChangeStatusOrder: null,

            CustomerTransferBankName: null,
            CustomerTransferAccountNumber: null,
            CustomerTransferAccountName: null,
            CustomerTransferAmount: null,
            CustomerTransferPhotoLink: null,
            CustomerTransferCreatedDatetime: null,
            CustomerTransferInformDatetime: null,

            // testDataTable
            headersShow:[],
            headers1: [
              { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'OrderID', align: 'center', sortable: false, value: 'id', width:'120px' },
              { text: 'เวลาที่สร้าง', align: 'left', value: 'createdDatetime', sortable: false, width:'170px' },
              { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false, width: '250px' },
              { text: 'ช่องทาง', align: 'center', value: 'channelLogo', sortable: false },
              { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
              { text: 'ยอดขาย', align: 'right', value: 'orderTotalAmount', sortable: false, width: '120px'  },
              { text: 'ประเภทการส่ง', align: 'center', value: 'isCod', sortable: false },
              { text: 'ยอด COD', align: 'right', value: 'codamount', sortable: false , width: '120px' },
              { text: 'สถานะออเดอร์', align: 'center', value: 'shippingStatusName', sortable: false, width: '130px' },
              { text: 'สถานะพัสดุ', align: 'center', value: 'parcelStatusName', sortable: false, width: '110px' },
              { text: 'เลขอ้างอิง', align: 'left', value: 'referenceNumber', sortable: false, width:'130px' },
              { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false, width:'130px' },
              { text: 'สร้างโดย', align: 'left', value: 'createdByStaffName', sortable: false, width:'130px' },
              { text: 'พนักงานขาย', align: 'left', value: 'salemanName', sortable: false, width:'130px' },
            ],

            headers2: [
              { text: 'ลำดับ', align: 'center', sortable: false, value: 'num', width:'90px' },
              { text: 'OrderID', align: 'center', sortable: false, value: 'id', width:'100px' },
              { text: 'ยอดขาย', align: 'right', value: 'orderTotalAmount', sortable: false, width: '100px'  },
              { text: 'รายละเอียดออเดอร์', align: 'left', value: 'createdDatetime', sortable: false, width:'300px' },
              { text: 'รายละเอียดขนส่ง', align: 'left', value: 'receiverName', sortable: false, width: '300px' },
              // { text: 'ช่องทาง', align: 'center', value: 'channelLogo', sortable: false },
              // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
              // { text: 'ประเภทการส่ง', align: 'center', value: 'isCod', sortable: false },
              // { text: 'ยอด COD', align: 'right', value: 'codamount', sortable: false , width: '120px' },
              // { text: 'สถานะออเดอร์', align: 'center', value: 'shippingStatusName', sortable: false, width: '130px' },
              // { text: 'สถานะพัสดุ', align: 'center', value: 'parcelStatusName', sortable: false, width: '110px' },
              // { text: 'เลขอ้างอิง', align: 'left', value: 'referenceNumber', sortable: false, width:'130px' },
              // { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false, width:'130px' },
              // { text: 'สร้างโดย', align: 'left', value: 'createdByStaffName', sortable: false, width:'130px' },
              // { text: 'พนักงานขาย', align: 'left', value: 'salemanName', sortable: false, width:'130px' },
            ],
            IsHeaderShot: false,

            search: '',
            dialog: false,
            showdataTable: [],

            sheetSearching: true,

            detailOrder:[],
            detai_CreatedateTime: '',
            detailOrderProduct:[],
            detailOrderBox:[],
            detai_ID:'',
            detai_statusId : '',
            detai_statusName : '',
            detai_ShopName: '',
            detai_ShopPhone: '',
            detai_ShopAddress:'',
            detai_ShipName: '',
            detai_ShipPhone: '',
            detai_ShippingAddress:'',
            detai_BankName:'',
            detai_AccountNumber:'',
            detai_AccountName:'',
            detai_Remark:'',
            detai_Weight:'',
            detai_Widht:'',
            detai_Height:'',
            detai_Long:'',
            detai_BoxPrice: '',
            detai_CODPercent: '',
            detai_CODCharged: '',
            detai_Discount: '',
            detai_RemotePrice: '',
            detai_DeliveryPrice: '',
            detai_PackPrice: '',
            detail_TrackingNo: '',
            detai_ChannelName: '',
            detai_StaffName: '',
            detai_totalPrice: '',
            detai_DeliveryCost: '',
            detail_Token: '',
            detail_IsCOD: '',
            detai_CustomerType: '',
            get_token: '',
            detai_CODAmount: '',
            shipping_status: [],
            detail_sum_unintPrice: '',
            detail_sum_unintPrice_and_DeliveryCost: '',
            detai_RealTransferDatetime: '',
            detai_ExpressCompanyID: '',

            detai_shiping_staus_update_select: null,

            shipingId: '',
            OrderMasterID: '',
            itemID: '',
            stockID: '',
            stockQuantity: '',
            selected: 9999,

            // paginations
            isPage: 0,
            page: 1,
            page_tmp: null,
            limits: 50,
            offsets: 0,
            pageLength: null,

            // mobile detail
            showAndHind_key: false,
            index_key: null,

            // export_to_excel
            dialog_excel: false,
            dialog_excel_select: null,
            dialog_excel_items: [
              {text: 'ทั้งหมด', value: null},
              {text: 'เปิดออร์เดอร์', value: 10},
              {text: 'รอตรวจยอด', value: 20},
              {text: 'รอแพ็ค', value: 30},
              {text: 'กำลังแพ็ค', value: 40},
              {text: 'แพ็คเรียบร้อย', value: 50},
              {text: 'เตรียมจัดส่ง', value: 60},
              {text: 'จัดส่งแล้ว', value: 70},
              {text: 'ยกเลิก', value: 90},
            ],

            startDate: null,
            menuStartDate: false,
            checkStart: true,

            endDate: null,
            menuEndDate: false,
            checkEnd: true,

            singleSelect: false,
            selected_order:[],

            // time
            time1: "00:00",
            time2: "23:59",
            menutime1: false,
            menutime2: false,

            // print
            inv_dialog_printinvoice: false,
            dialog_printcoverpage: false,
            inv_menu_date_print: false,
            templateorderpage:null,
            // inv_dateprint: new Date().toISOString().substr(0, 10),
            invoice_print: {
              ShopID: localStorage.getItem("shop_id"),
              invorder : [],
              titleprint_main: null,
              titleprint_secondary: null,
              date: new Date().toISOString().substr(0, 10)
            },
            coverpages_print:{
              sizepage: "a4",
              // templateorderpage:[],
              ShopID:localStorage.getItem("shop_id"),
              OrderID:[]
            },
            inv_titleprintselect: [
              {text: '---กรุณาเลือก---', value: 0},
              {text: 'ใบเสร็จรับเงิน', value: 1},
              {text: 'ใบกำกับภาษี', value: 2},
              {text: 'ใบส่งของ', value: 3},
              {text: 'ใบวางบิล', value: 4},
              {text: 'ใบแจ้งหนี้', value: 5},
            ],
            // select_templateorderpage:[
            //   {text: '---กรุณาเลือก---', value: 0},
            //   {text: '1 ออเดอร์ต่อหน้า', value: 1},
            //   {text: '2 ออเดอร์ต่อหน้า', value: 2},
            //   {text: '4 ออเดอร์ต่อหน้า', value: 3},
            //   {text: '6 ออเดอร์ต่อหน้า', value: 4},
            // ],
            invorderhead:[
              { align: 'center', text: 'OrderID', value: 'orderid', sortable: false  },
              { align: 'center', text: 'วันที่', value: 'dateString', sortable: false },
              { align: 'center', text: 'หมายเลขเอกสาร', value: 'invoiceno', sortable: false },
              
              { align: 'center', text: '', value: 'delete', sortable: false },

            ],
            sizepage: "a4", 

            // Export Excel
            loadingExcel: 0,
            datasetExcel: [],
            datasetExcel_new: [],
            datasetExcelShotFormat_new : [],

            ExcelType : false,

            // new excel
            excel_order_header : [
              { label: "เลขออเดอร์", field: "orderID", width: 15},
              { label: "สถานะ", field: "shippingStatusName", width: 15},
              { label: "เวลาที่สร้าง", field: "createdDatetime", width: 20},
              { label: "ขนส่ง", field: "expressCompanyName", width: 15},
              { label: "เวลาที่ส่งให้ขนส่ง", field: "sendToExpressDatetime", width: 20},
              { label: "เวลาที่จัดส่งสำเร็จ", field: "parcelSuccessDateTime", width: 20},
              { label: "trackingNo", field: "trackingNo", width: 15},
              { label: "สถานะจัดส่ง", field: "parcelStatusName", width: 15},
              { label: "เบอร์ผู้ส่ง", field: "senderPhone", width: 15},
              { label: "ชื่อผู้ส่ง", field: "senderName", width: 20},
              { label: "ที่อยู่ผู้ส่ง", field: "senderAddress", width: 30},
              { label: "ตำบลผู้ส่ง", field: "senderSubDistrict", width: 15},
              { label: "อำเภอผู้ส่ง", field: "senderDistrict", width: 15},
              { label: "จังหวัดผู้ส่ง", field: "senderProvince", width: 15},
              { label: "รหัสไปรษณีผู้ส่ง", field: "senderPostcode", width: 15},
              { label: "เบอร์ผู้รับ", field: "receiverPhone", width: 15},
              { label: "ชื่อผู้รับ", field: "receiverName", width: 20},
              { label: "ที่อยู่ผู้รับ", field: "receiverAddress", width: 30},
              { label: "ตำบลผู้รับ", field: "receiverSubDistrict", width: 15},
              { label: "อำเภอผู้รับ", field: "receiverDistrict", width: 15},
              { label: "จังหวัดผู้รับ", field: "receiverProvince", width: 15},
              { label: "รหัสไปรษณีผู้รับ", field: "receiverPostcode", width: 15},
              { label: "อีเมลผู้รับ", field: "receiverEmail", width: 25},
              { label: "FBผู้รับ", field: "receiverFacebook", width: 25},
              { label: "Lineผู้รับ", field: "receiverLine", width: 25},
              { label: "ที่อยู่ผู้ส่ง อ้างอิง", field: "tempSenderAddress", width: 40},
              { label: "ที่อยู่ผู้รับ อ้างอิง", field: "rempAddress", width: 40},
              { label: "ช่องทางการขาย", field: "channelName", width: 20},
              { label: "ธนาคาร", field: "shopBank", width: 30},
              { label: "จำนวนเงินรับโอน", field: "shopBankAmount", width: 15},
              { label: "สร้างโดย", field: "createdByStaffShopName", width: 25},
              { label: "พนักงานขาย", field: "salemanName", width: 25},
              { label: "สินค้า", field: "itemName", width: 30},
              { label: "SKU", field: "itemSKU", width: 15},
              { label: "SerialNo", field: "itemSerialNumber", width: 15},
              { label: "Barcode", field: "itemBarcode", width: 15},
              { label: "ลักษณะ1", field: "itemProp1Description", width: 15},
              { label: "ลักษณะ2", field: "itemProp2Description", width: 15},
              { label: "Brand", field: "itemBrand", width: 15},
              { label: "จำนวน", field: "itemQuantity", width: 15},
              { label: "ราคาขาย", field: "itemPrice", width: 15},
              { label: "ส่วนลดสินค้า", field: "itemDiscount", width: 15},
              { label: "ประเภท", field: "isCODText", width: 15},
              { label: "ยอด COD", field: "codAmount", width: 15},
              { label: "ราคาสินค้ารวม", field: "totalPrice", width: 15},
              { label: "ส่วนลด Seller", field: "totalSellerDiscount", width: 15},
              { label: "ส่วนลด Platform", field: "totalPlatformDiscount", width: 15},
              { label: "ส่วนลดออเดอร์", field: "orderDiscount", width: 15},
              { label: "ค่าส่ง", field: "deliveryCost", width: 15},
              { label: "ส่วนลดค่าขนส่งจาก seller", field: "sellerDeliveryDiscount", width: 15},
              { label: "ส่วนลดค่าขนส่งจาก platofrm", field: "platformDeliveryDiscount", width: 15},
              { label: "Vat", field: "vat", width: 15},
              { label: "ยอด VAT", field: "vatAmount", width: 15},
              { label: "รวมทั้งหมด", field: "orderTotalAmount", width: 15},

              { label: "ค่าขนส่ง", field: "deliveryPrice", width: 15},
              { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice", width: 15},
              { label: "ค่าบริการ COD", field: "codCharged", width: 15},
              { label: "ค่ากล่อง", field: "boxPrice", width: 15},
              { label: "ค่าแพ็ค", field: "packPrice", width: 15},
              { label: "ส่วนลด", field: "discount", width: 15},
              { label: "ค่าบับเบิ้ล", field: "bubblePrice", width: 15},
              { label: "ค่าเชื่อมต่อ", field: "vrichTransactionFee", width: 15},
              { label: "เลขอ้างอิง", field: "referenceNumber", width: 15},
              { label: "หมายเหตุ", field: "remark", width: 35},
              { label: "รายการโอน", field: "dataPayment", width: 50},
              { label: "ชื่อกล่อง", field: "boxName", width: 50},
            ],

            excel_order_header_shot : [
              { label: "เลขออเดอร์", field: "orderNo"},
              { label: "สถานะ", field: "orderStatus"},
              { label: "วันที่สร้าง", field: "createdDatetime"},
              { label: "วันที่แพ็ค", field: "packedDate"},
              { label: "ชื่อสินค้า", field: "productName"},
              { label: "SKU", field: "sku"},
              { label: "Barcode", field: "barcode"},
              { label: "รหัส CF", field: "cfCode"},
              { label: "จำนวน", field: "quantity"},
              { label: "ร้านค้า", field: "shopName"},
              { label: "ผู้รับ", field: "receiverName"},
              { label: "ค่าแพ็ค", field: "packPrice"},
              { label: "ค่ากล่อง", field: "boxPrice"},
              { label: "ค่าขนส่ง", field: "deliveryPrice"},
              { label: "ค่ากล่อง + ค่าแพ็ค", field: "totalPackBoxPrice"},
              { label: "ค่า Bouble", field: "bubblePrice"},
              // { label: "กล่อง", field: "postbox_New"},
              { label: "ขนาดกล่องตอนเลือกแพ็ค", field: "postbox_New"},
              { label: "ขนาด", field: "cubic"},
              { label: "ช่องทางการขาย", field: "channelName"},
              { label: "TrackingNo", field: "trackingNo"},
              { label: "Express", field: "expressCompanyName"},
            ],

        }),

        computed: {
          computed_search_date() {
            return this.formatDate(this.search_date_range)
          },

          computed_search_sent_date() {
            return this.formatDate(this.sent_date_range)
          },

          computed_search_tranferred_date() {
            return this.formatDate(this.transferred_date_range)
          },
          computed_date_print() {
            return this.formatDate(this.dateprint)
          },
        },

        watch: {
          startDate: async function(newVal, oldVal) {
            if (new Date(newVal)>new Date(this.endDate)){
              this.checkStart = false
              this.startDate = oldVal
            }else if(this.checkStart){
              try {
                this.$refs.menuStartDate.save(this.startDate)
              }catch(err){
                console.log(err);
              }
            }else{
              this.checkStart = true
            }
          },
          endDate: async function(newVal, oldVal) {
            if (new Date(newVal)<new Date(this.startDate) || new Date(newVal)>new Date()){
              this.checkEnd = false
              this.endDate = oldVal
            }else if(this.checkEnd){
              try {
                this.$refs.menuEndDate.save(this.endDate)
              }catch(err){
                console.log(err);
              }
            }else{
              this.checkEnd = true
            }
          },
        },

        async created() {

           //เก็บภาษามาไว้ใน use_language
            this.use_language = await this.get_languages(localStorage.getItem("set_language"));

               //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
            this.get_authorize_staffshop();



            localStorage.removeItem("OrderToken")
            this.shop_id = localStorage.getItem("shop_id")
            this.staff_id = localStorage.getItem("staff_id")
            // แบบเก่ารอการแก้ไข
            this.CanApprove = JSON.parse(localStorage.getItem("permission"))['ApproveOrder']
            this.canChangeStatusOrder = JSON.parse(localStorage.getItem('permission'))['ChangeStatusOrder']
            this.submit_edit_order = JSON.parse(localStorage.getItem('submit_edit_order'))
            this.SeeOtherPeoplesOrder = JSON.parse(localStorage.getItem('permission'))['SeeOtherPeoplesOrder']

            // แบบใหม่
            // this.canSeeReport = JSON.parse(localStorage.getItem('permission'))['PageReport']
            this.menuCode500 = localStorage.getItem("500") //รายงาน
            // this.AddOrder_permisstion = JSON.parse(localStorage.getItem('permission'))['AddOrder']
            this.menuCode310 = localStorage.getItem("310") //สร้างออเดอร์ใหม่

            this.headersShow = this.headers1

            localStorage.removeItem('submit_edit_order')

            this.getShopChannel();
            if( this.submit_edit_order != null ){
              await this.select_shipping_status_button(localStorage.getItem("selected"))
              await this.load_data_tmp(localStorage.getItem("selected"), null, null)
            } else {
              await this.load_data_tmp(9999, null, null)
            }

            this.page_loading = false
        },

        methods: {
        get_languages,

            detail_page (id) {
              window.open('order-detail?id='+id, '_blank');
            },

            format_number,
            format_order_id,
            format_price,
            FormatInvoice,
            SetToDateTime,
            allowedDates: val => val <= SetToDateTime('date',new Date(),0),
            image_click(photo_link) {
                this.dialog_photo_link = photo_link
                this.dialog_photo = true
            },

            edit_order () {
              localStorage.setItem('OrderToken', this.get_token)
              this.$router.push('/edit-order')
            },

            edit_date_format_for_dotnet ( val ){
              const _date = new Date(val)
              var informDatetime_tmp = new Date( _date.getTime() + 7 * (60 * 60 * 1000) ).toISOString()
              return informDatetime_tmp
            },

            formatDate (date) {
              if (date.length == 0) return null

              if (date.length == 1) {
                const [year, month, day] = date[0].split('-')
                return `${day}-${month}-${year}`
              } else {
                const [year_from, month_from, day_from] = date[0].split('-')
                const [year_to, month_to, day_to] = date[1].split('-')
                if (date[0] < date[1]) {
                  return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
                } else {
                  return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
                }
              }
            },

            gen_num_data_table (index) {
              if(index != null && this.loading == false) {
                var start = null
                if(this.selected == 30){
                  start = 1 + this.offsets
                  return format_number(start + index)
                }else {
                  start = this.total_search_data_order_master - this.offsets
                  return format_number(start - index )
                }
              } else {
                return ""
              }
            },


            set_format_date_time (val) {
              if(val != null){
                var date_tmp = val.substring(0, 10)
                const date_split = date_tmp.split('-');
                var time_tmp = val.substring(11, 19)
                var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' '+ time_tmp
                return set_dattime
              } else {
                return ""
              }
            },
            updateFormattedDate(item){
              if (!item.date) return null
              const [year, month, day] = item.date.split('-')
              item.dateString = `${day}-${month}-${year}`
            },

            async load_data_tmp (shipping_status_id, search_data, offset) {

              if(search_data != null){

                var shipping_name = search_data.shipping_name
                var tracking_no = search_data.tracking_no
                var SHorderID = null
                if(search_data.SHorderID != null && search_data.SHorderID != ''){
                  if (search_data.SHorderID.substring(0, 2).toUpperCase() == 'PA'){
                    var str_tmp = search_data.SHorderID.toUpperCase()
                    str_tmp = str_tmp.replace("PA", "");
                    let isnum = /^\d+$/.test(str_tmp);
                    if(isnum){
                      SHorderID = str_tmp
                    }else {
                      SHorderID = 0
                    }
                  } else {
                    let isnum = /^\d+$/.test(search_data.SHorderID);
                    if(isnum){
                      SHorderID = search_data.SHorderID
                    }else {
                      SHorderID = 0
                    }
                  }
                }
                var SH_shop_staff = search_data.SH_shop_staff
                var SH_phone = search_data.SH_phone
                var SH_ReferenceNumber = search_data.SH_ReferenceNumber

                var created_date_range = search_data.created_date_range
                var dateFrom = null
                var dateTo = null
                if(created_date_range.length != 0){
                  if(created_date_range.length == 1) {
                    dateFrom = created_date_range[0] + " 00:00:00"
                    dateTo   = created_date_range[0] + " 23:59:59"
                  } else {
                    dateFrom = created_date_range[0] + " 00:00:00"
                    dateTo   = created_date_range[1] + " 23:59:59"
                  }
                  if(this.shop_id==2){ 
                    var t1 = (new Date(dateFrom.split(' ')[0])).getTime()
                    var t2 = (new Date(dateTo.split(' ')[0])).getTime()
                    var day= parseInt(Math.floor((t2-t1)/(24*3600*1000)));
                    if(day>90){
                      Swal.fire({
                        type: 'error', 
                        text: 'วันที่ค้นหาต้องอยู่ในช่วง 90 วัน',
                      });
                      return;
                    }
                  }
                }


                var chanel_fackbook = search_data.chanel_fackbook
                var chanel_email = search_data.chanel_email
                var chanel_line = search_data.chanel_line
                var paid_status_id = 0
                if(search_data.paid_status_id != null){
                  paid_status_id = search_data.paid_status_id
                }
                var parcel_status_id = search_data.parcel_status_id
                var sent_date_range = search_data.sent_date_range
                var sent_dateFrom = null
                var sent_dateTo = null
                if(sent_date_range.length != 0){
                  if(sent_date_range.length == 1) {
                    sent_dateFrom = sent_date_range[0] + " 00:00:00"
                    sent_dateTo   = sent_date_range[0] + " 23:59:59"
                  } else {
                    sent_dateFrom = sent_date_range[0] + " 00:00:00"
                    sent_dateTo   = sent_date_range[1] + " 23:59:59"
                  }
                  if(this.shop_id==2){ 
                    var t3 = (new Date(sent_dateFrom.split(' ')[0])).getTime()
                    var t4 = (new Date(sent_dateTo.split(' ')[0])).getTime()
                    var day2= parseInt(Math.floor((t3-t4)/(24*3600*1000)));
                    if(day2>90){
                      Swal.fire({
                        type: 'error', 
                        text: 'วันที่ค้นหาต้องอยู่ในช่วง 90 วัน',
                      });
                      return;
                    }
                  }
                }
                var transferred_date_range = search_data.transferred_date_range
                var transferred_dateFrom = null
                var transferred_dateTo = null
                if(transferred_date_range.length != 0){
                  if(transferred_date_range.length == 1) {
                    transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
                    transferred_dateTo   = transferred_date_range[0] + " 23:59:59"
                  } else {
                    transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
                    transferred_dateTo   = transferred_date_range[1] + " 23:59:59"
                  }
                  if(this.shop_id==2){ 
                    var t5 = (new Date(transferred_dateFrom.split(' ')[0])).getTime()
                    var t6 = (new Date(transferred_dateTo.split(' ')[0])).getTime()
                    var day3= parseInt(Math.floor((t5-t6)/(24*3600*1000)));
                    if(day3>90){
                      Swal.fire({
                        type: 'error', 
                        text: 'วันที่ค้นหาต้องอยู่ในช่วง 90 วัน',
                      });
                      return;
                    }
                  }
                }
              } else {
                {
                    dateFrom = null
                    dateTo = null
                    sent_dateFrom = null
                    sent_dateTo = null
                    transferred_dateFrom = null
                    transferred_dateTo = null
                    SH_shop_staff = null
                    SH_phone = null
                    paid_status_id = 0
                    shipping_name = null
                    chanel_email = null
                    chanel_fackbook = null
                    chanel_line = null
                    SH_ReferenceNumber = null
                    tracking_no = null
                    parcel_status_id = null

                }
              }

              if(offset == null){
                offset = 0
              }
              if(shipping_status_id == 9999){
                shipping_status_id = null
              }

              var orderby = 2
              if (shipping_status_id == 30) {
                orderby = 1
              } else {
                orderby = 2
              }

              // check perrmisstion เห็น ออเดอร์คนอื่นหรือไม่
              var staff_sumery_id = null
              // if(this.SeeOtherPeoplesOrder == false){
              //   SH_shop_staff = localStorage.getItem('staff_id')
              //   staff_sumery_id =  localStorage.getItem('staff_id')
              // }
              if(this.shop_id==2 && dateFrom ==null&& dateTo ==null){
                const start = new Date();
                dateFrom = start.getFullYear()+'-'+(start.getMonth()+1)+'-'+start.getDate();
                dateTo=dateFrom
              }
              await axios.all([
                axios.post(orderService_dotnet + 'Order/get-order-list',{
                    "orderID": SHorderID,
                    "branchID": null,
                    "shopID": this.shop_id,
                    "warehouseID": null,
                    "dateFrom": dateFrom,
                    "dateTo": dateTo,
                    "SendToExpressDatetime_From": sent_dateFrom,
                    "SendToExpressDatetime_To": sent_dateTo,
                    "packDateFrom": transferred_dateFrom,
                    "packDateTo": transferred_dateTo,
                    "createdByStaffID": SH_shop_staff,
                    "receiverPhone": SH_phone,
                    "PaidStatusID": paid_status_id,
                    "receiverName": shipping_name,
                    "ReceiverEmail":chanel_email,
                    "ReceiverFacebook": chanel_fackbook,
                    "ReceiverLine": chanel_line,
                    "referenceNumber": SH_ReferenceNumber,
                    "trackingNo": tracking_no,
                    "shippingStatusID": shipping_status_id,
                    "parcelStatusID": parcel_status_id,
                    "isNeedStatusCount": true,
                    "channelIDList": this.channelListSeleced.map(function(item){return item.uniqueID;}),
                    "seeOtherPeoplesOrder": true,//this.SeeOtherPeoplesOrder,
                    "skip": offset,
                    "take": 50,
                    "orderby": orderby
                },{ headers: this.header_token}),
                // axios.post(orderService_dotnet + 'Order/get-order-summary',{
                //     "orderID": null,
                //     "branchID": null,
                //     "shopID": this.shop_id,
                //     "warehouseID": null,
                //     "dateFrom": null,
                //     "dateTo": null,
                //     "receiverName": null,
                //     "referenceNumber": SH_ReferenceNumber,
                //     "trackingNo": tracking_no,
                //     "shippingStatusID": null,
                //     "parcelStatusID": parcel_status_id,
                //     "isNeedStatusCount": false,
                //     "createdByStaffID": SH_shop_staff,
                //     "seeOtherPeoplesOrder": true,//this.SeeOtherPeoplesOrder,
                //     "skip": 0,
                //     "take": offset,
                //     "orderby": orderby
                // },{ headers: this.header_token}),
                axios.post(shopService_dotnet + 'Shop/get-shop-staff',{'shopID': this.shop_id},{ headers: this.header_token}),
              ]).then(axios.spread((res_order_master,   res_shop_staff) => {
                this.index_key = null
                this.showdataTable = res_order_master.data.orderList

                var status_count = {
                  "Cancel": 0,
                  "OpenOrder": 0,
                  "Packed": 0,
                  "Packing": 0,
                  "Ready": 0,
                  "Sent": 0,
                  "SumAllShippingStatus": 0,
                  "WaitCheck": 0,
                  "WaitPack": 0
                }
                var sum_total = 0

                for (var i = 0; i < res_order_master.data.statusCount.length; i++) {
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 10){
                    status_count.OpenOrder = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 20){
                    status_count.WaitCheck = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 30){
                    status_count.WaitPack = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 40){
                    status_count.Packing = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 50){
                    status_count.Packed = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 60){
                    status_count.Ready = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 70){
                    status_count.Sent = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                  // if(res_order_master.data.statusCount[i]['shippingStatusID'] == 80){
                  //   status_count.OpenOrder = res_order_master.data.statusCount[i]['count']
                  // }
                  if(res_order_master.data.statusCount[i]['shippingStatusID'] == 90){
                    status_count.Cancel = res_order_master.data.statusCount[i]['count']
                    sum_total = sum_total + res_order_master.data.statusCount[i]['count']
                  }
                }
                status_count.SumAllShippingStatus = sum_total
                this.summary_shipping_status = status_count
                this.total_search_data_order_master = res_order_master.data.searchResultCount
                var percent = 0
                // var total = res_order_summary.data.parcelReturned + res_order_summary.data.parcelDelivered
                // if (total > 0 && res_order_summary.data.parcelDelivered > 0) {
                //   percent = ((res_order_summary.data.parcelDelivered*100)/total).toFixed(2)
                // }
                // var codAmount_tmp = res_order_summary.data.codAmount
                // var codTransferred_tmp = res_order_summary.data.codTransferred
                // var codWaitingToTransfer_tmp = res_order_summary.data.codWaitingToTransfer
                // if(codAmount_tmp === undefined){
                //   codAmount_tmp = 0
                // }

                // if(codTransferred_tmp === undefined){
                //   codTransferred_tmp = 0
                // }

                // if(codWaitingToTransfer_tmp === undefined){
                //   codWaitingToTransfer_tmp = 0
                // }
                // var summary_tmp ={
                //   "COD": res_order_summary.data.cod,
                //   "codAmount": this.format_price(codAmount_tmp),
                //   "NotCOD": res_order_summary.data.notCOD,
                //   "ParcelStatusID1": res_order_summary.data.parcelDeliverying,
                //   "ParcelStatusID2": res_order_summary.data.parcelReturned,
                //   "ParcelStatusID3": res_order_summary.data.parcelDelivered,
                //   "Percent": percent,
                //   "Transferred": this.format_price(codTransferred_tmp),
                //   "WaitingToTransfer": this.format_price(codWaitingToTransfer_tmp)
                // }
                // this.summary_parcel_status = summary_tmp
                this.SH_shop_staff_items = []

                for (var i2 = 0; i2 < res_shop_staff.data.length; i2++) {
                  this.SH_shop_staff_items.push({'text': res_shop_staff['data'][i2]['name'], 'value': res_shop_staff['data'][i2]['id']})
                }
                this.getpagelength(this.total_search_data_order_master)
              }));
            },

            async select_shipping_status_button(shipping_status_id) {
                this.page = 1
                this.SearchingClearData()
                this.selected = shipping_status_id
                localStorage.setItem("selected", shipping_status_id)
                this.loading = true
                this.sheetSearching = true
                await this.load_data_tmp(shipping_status_id, null, null)
                this.loading = false
            },

            async Searching () {
              this.loading = true
              this.page = 1
              if (this.SH_shop_staff == '') { this.SH_shop_staff = null }
              this.IsSearch = true

              if(this.SHchanelFackbook == ''){ this.SHchanelFackbook = null } else { this.SHchanelFackbook }
              if(this.SHchanelLine == ''){ this.SHchanelLine = null } else { this.SHchanelLine }
              if(this.SHchanelEmail == ''){ this.SHchanelEmail = null } else { this.SHchanelEmail }


              if(this.SHshipName == ''){ this.SHshipName = null } else { this.SHshipName }
              if(this.SHtrackingNo == ''){ this.SHtrackingNo = null } else { this.SHtrackingNo }
              if(this.SHorderID == ''){ this.SHorderID = null } else { this.SHorderID }
              if(this.SH_phone == ''){ this.SH_phone = null } else { this.SH_phone }
              if(this.SH_ReferenceNumber == ''){ this.SH_ReferenceNumber = null } else { this.SH_ReferenceNumber }
 
              this.searchData = {
                "shipping_status_id": this.SHselectStatus,
                "shipping_name": this.SHshipName,
                "tracking_no": this.SHtrackingNo,
                "SHorderID": this.SHorderID,
                "SH_shop_staff": this.SH_shop_staff != null?this.SH_shop_staff['value']:null,
                "SH_phone": this.SH_phone != null?this.SH_phone:null,
                "SH_ReferenceNumber": this.SH_ReferenceNumber != null?this.SH_ReferenceNumber:null,
                "created_date_range": this.search_date_range,
                "chanel_fackbook": this.SHchanelFackbook,
                "chanel_line": this.SHchanelLine,
                "chanel_email": this.SHchanelEmail,
                "paid_status_id": this.select_search_paid_status_id,
                "parcel_status_id": this.select_search_parcel_status_id,
                "sent_date_range": this.sent_date_range,
                "transferred_date_range": this.transferred_date_range
              }
              this.selected = 9999
              this.tab_list = 0
              await this.load_data_tmp(this.searchData["shipping_status_id"], this.searchData, null)
              this.selected_order = []
              this.loading = false
            },

            SearchingClearData () {
              // this.SHselectStatus = []
              this.searchData = null
              this.SHselectStatus = 9999
              this.search_date_range = []
              this.SHshipName = null
              this.SHchanelFackbook = null
              this.SHchanelLine = null
              this.SHchanelEmail = null
              this.SHtrackingNo = null
              this.SHorderID = null
              this.SH_shop_staff = null
              this.SH_phone = null
              this.SH_ReferenceNumber = null
              this.select_search_paid_status_id = null
              this.select_search_parcel_status_id = null
              this.sent_date_range = []
              this.transferred_date_range = []
            },

            copyToken () {
              // this.detail_Token
              let TokenCodeToCopy = document.querySelector('#testing-code')
              TokenCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
              TokenCodeToCopy.select()

              try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'สำเร็จ' : 'ไม่สำเร็จ';
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'คัดลอก' + msg
                })
              } catch (err) {
                this.$swal({
                  type: 'warning',
                  title: 'เกิดข้อผิดพลาดบางอย่าง',
                  text: 'คัดลอกไม่สำเร็จ !!',
                })
              }

              /* unselect the range */
              TokenCodeToCopy.setAttribute('type', 'hidden')
              window.getSelection().removeAllRanges()
            },

            getpagelength (total_order_master) {
              var total = 0
              if(total_order_master != 0){
                total = total_order_master
              } else {
                total = 1
              }

              total = parseInt(total)
              this.pageLength = Math.ceil(total/this.limits)
              this.isPage = 0
              this.page_tmp = this.page
            },

            async changePage () {
              this.loading = true
              this.offsets = (this.page*this.limits)-this.limits

              if (this.page > 1 && this.page < this.pageLength ) {
                if(this.page_tmp != this.page){
                  if (this.searchData != null) {
                    await this.load_data_tmp(this.searchData["shipping_status_id"], this.searchData, this.offsets)
                  } else {
                    await this.load_data_tmp(this.selected, null, this.offsets)
                  }
                  this.page_tmp = this.page
                }
              } else if(this.page == 1,this.pageLength ) {
                if(this.page_tmp != this.page){
                  if (this.searchData != null) {
                    await this.load_data_tmp(this.searchData["shipping_status_id"], this.searchData, this.offsets)
                  } else {
                    await this.load_data_tmp(this.selected, null, this.offsets)
                  }
                  this.page_tmp = this.page
                }
              }
              this.loading = false
            },

            async get_count_order_excel_new () {
              this.loadingExcel = 1
              let res1 = await axios.post(orderService_dotnet+'Order/get-count-order-to-excel',{
                "shopID": localStorage.getItem("shop_id"),
                "shippingStatusID": this.dialog_excel_select,
                "dateFrom": this.startDate +  ' ' + this.time1,
                "dateTo": this.endDate + ' ' + this.time2 
                
              } , { headers: this.header_token})
              var fileCounter=1;
              const d = new Date();
              let dateExported =d.getFullYear().toString()+(d.getMonth()+1).toString() +d.getDate().toString() + d.getHours().toString()+d.getMinutes().toString()+d.getSeconds().toString();
              this.ExcelFileName = 'รายงานข้อมูลออเดอร์_'+dateExported+"_"+fileCounter;
              try {
                this.datasetExcel_new = []

                var percent = 1;
                var limits = 2000
                var total = res1.data
                var offsets = 0
                var loadingExcel = 0

                var Round =parseInt( total / limits);
                if(limits*Round < total){
                  Round=Round+1;
                }

                for (var i =1; i <=Round; i++) {
                  percent =parseInt(i / Round * 100)

                  var Ndata = await this.get_data_order_excel_new(limits,offsets)
                  for (var jj in Ndata){
                    this.datasetExcel_new.push(Ndata[jj])
                    if(this.datasetExcel_new.length>10000){
                      await document.getElementById("GetExcel").click();
                      fileCounter=fileCounter+1;
                      this.ExcelFileName = 'รายงานข้อมูลออเดอร์_'+dateExported+"_"+fileCounter;
                      this.datasetExcel_new=[];
                    }
                  }
                  this.loadingExcel = percent
                  offsets = offsets + limits;
                }

                await document.getElementById("GetExcel").click();
                this.datasetExcel_new = []
                this.loadingExcel = 0


              } catch (e) {
                this.$swal({
                  type: 'info',
                  title: 'เกิดข้อผิดพลาด',
                  text: 'กรุณาลองใหม่อีกครั้งหรือติดต่อฝ่าย IT !!',
                })
                this.loadingExcel = 0
                this.datasetExcel_new = []
              }



            },

            async get_data_order_excel_new (limits, offsets) {
              let response = await axios.post(orderService_dotnet+'Order/get-order-export-to-excel',{
                "shopID": localStorage.getItem("shop_id"),
                "shippingStatusID": this.dialog_excel_select,
                "dateFrom": this.startDate +  ' ' + this.time1,
                "dateTo": this.endDate + ' ' + this.time2,
                "limits" : limits ,
                "offsets" : offsets,
                "isSkipSecondRow":!this.IsSkipSecondRow
              } , { headers: this.header_token})
              if(response.data.length > 0){
                return response.data
              } else {
                return []
              }

            },


            async get_count_order_excel_new_shot_format () {
              this.loadingExcel = 1
              let res1 = await axios.post(orderService_dotnet+'Order/get-count-order-to-excel',{
                "shopID": localStorage.getItem("shop_id"),
                "shippingStatusID": this.dialog_excel_select,
                "dateFrom": this.startDate +  ' ' + this.time1,
                "dateTo": this.endDate + ' ' + this.time2
              } , { headers: this.header_token})


              try {
                this.datasetExcelShotFormat_new = []

                var percent = 1;
                var limits = 3000
                var total = res1.data
                var offsets = 0
                var loadingExcel = 0

                var Round =parseInt( total / limits);
                if(limits*Round < total){
                  Round=Round+1;
                }

                for (var i =1; i <=Round; i++) {
                  percent =parseInt(i / Round * 100)

                  var Ndata = await this.get_data_order_excel_new_shot_format(limits,offsets)
                  for (var jj in Ndata){
                    this.datasetExcelShotFormat_new.push(Ndata[jj])
                  }
                  this.loadingExcel = percent
                  offsets = offsets + limits;
                }

                await document.getElementById("GetExcelShot").click();
                this.datasetExcelShotFormat_new = []
                this.loadingExcel = 0


              } catch (e) {
                this.$swal({
                  type: 'info',
                  title: 'เกิดข้อผิดพลาด',
                  text: 'กรุณาลองใหม่อีกครั้งหรือติดต่อฝ่าย IT !!',
                })
                this.loadingExcel = 0
                this.datasetExcelShotFormat_new = []
              }



            },

            async get_data_order_excel_new_shot_format (limits, offsets) {
              let response = await axios.post(orderService_dotnet+'Order/get-order-export-to-excel-shot-format',{
                "shopID": localStorage.getItem("shop_id"),
                "shippingStatusID": this.dialog_excel_select,
                "dateFrom": this.startDate +  ' ' + this.time1,
                "dateTo": this.endDate + ' ' + this.time2,
                "limits" : limits ,
                "offsets" : offsets
              } , { headers: this.header_token})
              if(response.data.length > 0){
                return response.data
              } else {
                return []
              }

            },


            dialog_export_excel () {
              this.dialog_excel = true
              this.ExcelType = false
              this.startDate = new Date().toISOString().substr(0, 10)
              this.endDate = new Date().toISOString().substr(0, 10)
            },

            async inv_pre_print(){


              if (this.selected_order.length > 0) {
                try {
                  var order_list = []
                  for (var o = 0; o < this.selected_order.length; o++) {
                    order_list.push(this.selected_order[o]['id'])
                  }
                  let response = await axios.post(reportService_dotnet+'ShopInvoiceOrder/get-invoice-no-before-print', {
                    "orderList":order_list,
                    "ShopID": this.shop_id
                  },
                  { headers: this.header_token})

                  this.invoice_print.invorder = []
                  for (var i=0; i<response.data.length; i++ ) {

                    var splitedText=response.data[i]['dateString'].split('-')
                    this.invoice_print.invorder.push(
                      {
                        "orderId":response.data[i]['orderMasterID'],
                        "invoiceNo":response.data[i]['invoiceNo'],
                        "dateString":response.data[i]['dateString'],
                        "date": splitedText[2]+'-'+splitedText[1]+'-'+splitedText[0],
                         "startDateMenu":false,

                      })
                  }
                  console.log(this.invoice_print.invorder);
                  this.inv_dialog_printinvoice = true
                } catch (error) {
                  this.$swal({
                    type: 'warning',
                    title: "ดำเนินการสำเร็จ",
                  })
                }
              }
              else{
                this.$swal({
                  type: 'warning',
                  title: "กรุณาเลือกออเดอร์เพื่อดำเนินการ",
                })
              }
            },

            set_zero (val) {
              val = val.toString()
              if(val < 10){
                return "0"+val
              }
              return val
            },

            async inv_printinvoice(){
              if(this.invoice_print.titleprint_main == null || this.invoice_print.titleprint_main.length == 0){
                this.$refs.refinvtitleprintmain.focus()
                return
              }

              if(this.invoice_print.invorder.length == 0){
                return
              }
              if(this.invoice_print.titleprint_secondary != null){
                if(this.invoice_print.titleprint_secondary.length == 0 ){
                  this.invoice_print.titleprint_secondary = null
                }
              }


              const today = new Date();
              const date = today.getFullYear()+'-'+(this.set_zero(today.getMonth()+1))+'-'+this.set_zero(today.getDate());
              this.invoice_print.date = date
   

              if(this.invoice_print.titleprint_secondary == null){
                 this.invoice_print.titleprint_secondary = {'text':null,'value':null}
               }
              localStorage.setItem('INVPrint',  JSON.stringify(this.invoice_print))
              localStorage.setItem('Sizepage',  this.sizepage)
              window.open('/print-tax-invoice');
              this.clear_printinvoice()
              this.inv_dialog_printinvoice = false

            },

            cover_pages(){
              if (this.selected_order.length > 0) {
                for (var i=0; i<this.selected_order.length; i++ ) {
                  this.coverpages_print.OrderID.push({"OrderId":this.selected_order[i]['id']})
                }
                this.dialog_printcoverpage = true
              }
              else{
                this.$swal({
                  type: 'warning',
                  title: "กรุณาเลือกออเดอร์เพื่อดำเนินการ",
                })
              }
            },

            async get_authorize_staffshop () {
          if(localStorage.getItem("320") == 2)
          {
           this.authorize = true
          }
        },
        async getShopChannel(){
          let res1 = await axios.get(orderService_dotnet+'Order/get-shop-all-channel?ShopID='+this.ShopID,
                { headers: this.header_token});
          this.channelList = res1.data.items;

        },
            printcoverpage(){
              localStorage.setItem('Print_Coverpage',  JSON.stringify(this.coverpages_print))
              window.open('/print-cover-pages');
              this.clear_printcoverpage()
              this.dialog_printcoverpage = false
            },

            clear_printinvoice(){
              this.invoice_print = {
                "ShopID" : localStorage.getItem("shop_id"),
                "printorder":[{"invorder": [],
                          "titleprint_main": [],
                          "titleprint_secondary": [],
                          "date": new Date().toISOString().substr(0, 10)
              }]
              }
            },

            remove_data_invoice (index) {
              this.invoice_print.invorder.splice(index, 1);
            },

            clear_printcoverpage(){
              this.coverpages_print = {
                  sizepage: "a4",
                // templateorderpage:[],
                ShopID:localStorage.getItem("shop_id"),
                OrderID:[]
              }
            },
            async cancle_order(item){
              if (item.invoicePackhaiToBranchId == null) {
                Swal.fire({
                  icon: 'warning',
                  title: 'ต้องการยกเลิกออเดอร์',
                  text: 'PA'+item.id ,
                  confirmButtonText: 'Yes!',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  allowOutsideClick: false
                }).then(async (result) => {
                if (result.value) {
                    this.loading = true
                    this.load_data_tmp(9999, null, null)
                    await axios.all([
                        axios.post(orderService_dotnet+'Order/cancel-order', {
                            "orderID": item.id,
                            "shippingAddressID": item.shippingAddressID,
                            "shopID": this.shop_id
                        },{headers:this.header_token}),
                    ])
                    .then(axios.spread((data) => {
                        if(data.status == 200){

                            Swal.fire({
                                        toast: true,
                                        timer: 1500,
                                        showConfirmButton: false,
                                        position: 'top',
                                        icon: 'success',
                                        title: "ยกเลิกสำเร็จ"
                                      })
                        }
                    }));
                    this.loading = false
                    }
                })
              }
            },
        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }

</style>
